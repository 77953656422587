body{
	background:#000000;
	font-size: 12px;
	font-weight: 400;
}
body.dark-layout {
	color: #b4b7bd;
	background-color: #000000; }
	
.incident-list .incident-title {
	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 120%;
	color:#FEF753;
}

.incident-list .incident-title:hover {
	text-decoration: underline;
	cursor: pointer;
}

.incident-list .help-icons {
	margin-left: 5px;
}

.incident-list .icon {
	height: 18px;
	margin-bottom: 2px;
}

.incident-list .incident {
	margin-bottom: 24px;
}
.incident-list .location-time {
	font-family: Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	margin-top: 2px;
	color: rgba(255, 255, 255, 0.7);
}

.incident-list .description {
	font-family: Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #FFFFFF;
	margin-top: 8px;
}

.icon-description {
	display: flex;
	flex-direction: row !important;
	justify-content: space-between;
	gap: 24px;
	margin-top: 24px;
	margin-bottom: 32px;
}

.icon-description .col {
	display: flex;
	flex-direction: row !important;
	padding: 0px;
}

.icon-description .icon {
	height: 18px;
	margin-right: 8px;
	margin-bottom: 2px;
}

.icon-description .description {
	font-family: Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #FFFFFF;
}

.details-modal .incident-title {
	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 120%;
	color:#FEF753;
}

.details-modal .location-time {
	font-family: Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	margin-top: 2px;
	color: rgba(255, 255, 255, 0.7);
}

.details-modal .close-modal-button {
	height: 20px;
	position: absolute;
	top: 20px;
	right: 20px;
}

.details-modal .close-modal-button:hover {
	height: 24px;
	top: 18px;
	right: 18px;
}

.details-modal .description {
	font-family: Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: #FFFFFF;
	padding: 0px;
	align-self: center;
}

.details-modal .open-in-new {
	height: 12px;
	color: #52A5D7;
	margin-left: 2px;
	margin-bottom: 0px;
}

.details-modal .link-text {
	font-family: Helvetica;
	font-style: italic;
	font-weight: normal;
	font-size: 12px;
	text-decoration: underline;
	color: #52A5D7;
}

.details-modal .link-text:hover {
	color: #52A5D7;
	font-weight: bold;
}

.details-modal .support .title {
	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	color: #FFFFFF;
	margin-right: 8px;
	flex-grow: 0;
}

.details-modal .title-with-line {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 36px;
	margin-bottom: 12px;
}

.details-modal .divider{
	flex-grow: 1;
	height: 1px;
	background-color:#FFFFFF;
}

.details-modal .support .icon {
	height: 18px;
	width: 18px;
	padding: 0px;
	flex: 0;
	margin-right: 8px;
	margin-bottom: 2px;
}

.details-modal .support .row {
	margin: 4px 0px;
}

.details-modal .support .col {
	padding: 0px;
}

// .rdt_TableCell{
// 	background:#000;
// 	font-family: Helvetica;
// 	font-style: normal;
// 	color:#FFFFFF;
// }

.rdt_TableCell{
	cursor: pointer;
}

.rdt_TableCell .selected{
	color:#FEF753;
	font-weight: bold;
}

// .rdt_TableRow{
// 	background:#000;
// }

.rdt_TableHeader{
	background:#000000;
}


// .rdt_TableHeadRow{
// 	background:#FEF753;
// 	color:#000000;
// 	font-weight: bold;
// }

.maptooltip {
	background: #000;
}

.maptooltip .state {
	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 22px;
	color: #FFFFFF;
	margin-top:4px;
}

.maptooltip .casenumber {
	font-family: Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #FFFFFF;
	margin-top:4px;
}

.dark-layout .card {
	background-color: #000000;
	box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.24); 
	margin-bottom: .5rem;
}

.dark-layout .card-title {
	color:#FEF753;
	font-weight: 500;
	font-size: 1.285rem;
}	
  
.dark-layout .card .card-footer {
	border-color: #000000; 
}

.dark-layout label {
    color: #d0d2d6;
}
.mb-1, [dir] .my-1 {
    margin-bottom: 1rem!important;
}

.dark-layout .btn-loadmore {
	background-color:  #FEF753 !important;
	color: #000000 !important;
}
.logo {
	max-width: 32px;
	margin: 1rem;
}
.header {
	color: #FEF753 !important;
}
.header h4{
	color: #FEF753 !important;
}
.footer {
	margin: 2em;
	color: #FEF753
}
.disclaimer {
	font-size: small;
	padding: 0.8rem;
	color:lightgray;
}
.contact_us {
	color: #FEF753 !important;
}

.container{
    max-width: 100%;
}

input.form-control, textarea.form-control {
    background-color: #283046;
	color: #b4b7bd;
	font-size: 1rem;
}
select.form-control {
    background-color: #283046;
    border-color: #3b4253;
	color: #b4b7bd;
}
.h1, h1 {
    font-size: 2rem;
}